// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { black, primary, gray } from '../../utils/colors';

import { RowSummary, RowDetails } from '../../components/StyledComponents';
import { Text } from '../../components/text';

import { ClassRow } from './ClassRow';
import {
  getInstitutionChildrenAction,
  getInstitutionClassesWithUsersAction,
  getInstitutionLicenseUsageAction,
  getInstitutionContractsAction,
} from '../../redux/actions/accountManagement';
import { INCLUDE_RESERVED, usedText } from './util';
import { idWithFilter } from '../../redux/reducers/accountManagement';
import { Institution, Contract } from '../../../types/routes/accountManagement';
import { countLicensesLeft } from './util';
import { displayName, institutionSortOrder } from '../../utils/institution';
import { classSortOrder } from '../../utils/class';
import { LicenseStatusText } from './types';

// This has a lot of code duplication with ManageInstitution.tsx

export const InstitutionRow = ({ institution, filter }: { institution: Institution; filter: Contract[] }) => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const timing = query.get('timing') ? true : false;
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.accountManagement.institutions);
  const fetchingInstitution = useSelector((state) => state.accountManagement.fetchingInstitution);
  const children = institution.children.map((id) => institutions[id]).filter((x) => x);
  children.sort(institutionSortOrder);

  const allClasses = useSelector((state) => state.accountManagement.classesWithUsers);
  const fetchingClassesWithUsers = useSelector((state) => state.accountManagement.fetchingClassesWithUsers);
  const classes = institution.classes.map((id) => allClasses[id]).filter((x) => x);
  classes.sort(classSortOrder);

  const institutionContracts = useSelector((s) => s.accountManagement.institutionContracts[institution.id]);
  const licenseUsage = useSelector(
    (s) =>
      s.accountManagement.institutionLicenseUsage[
        idWithFilter(
          institution.id,
          filter.map((x) => x.id),
        )
      ],
  );

  useEffect(() => {
    if (!licenseUsage) dispatch(getInstitutionLicenseUsageAction({ institutionId: institution.id, filter }));
  }, [filter]);

  useEffect(() => {
    if (!institutionContracts) dispatch(getInstitutionContractsAction(institution.id));
  }, []);

  // Use localStorage instead of REACT state mechanism
  const statePath = `admin/manage/institution/${institution.id}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };
  // Use this to trigger a download of the children institutions and classes.
  const [display, setDisplay] = useState<boolean>(state.open);

  const onRowToggle = (target: EventTarget) => {
    state.open = (target as HTMLDetailsElement).open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
    setDisplay(state.open);
  };

  useEffect(() => {
    // Dispatch all children of the institution, if open
    if (state.open && !fetchingInstitution)
      for (const childId of institution.children)
        if (!(childId in institutions)) {
          if (debugPrint) console.log(`*** dispatch get ${institution.id} children`);
          dispatch(getInstitutionChildrenAction({ id: institution.id, timing }));
          // Only fetch one at a time
          break;
        }
  }, [fetchingInstitution, display]);

  useEffect(() => {
    if (state.open && !fetchingClassesWithUsers)
      for (const c of institution.classes) {
        // Only get one set at a time
        if (classes.every((cc) => cc.id != c)) {
          if (debugPrint) console.log(`*** dispatch get ${institution.id} classes`);
          dispatch(getInstitutionClassesWithUsersAction({ id: institution.id, timing }));
          // Only fetch one at a time
          break;
        }
      }
  }, [fetchingClassesWithUsers, display]);

  const filteredContracts = institutionContracts?.filter((c1) => filter.some((c2) => c1.id == c2.id));
  const licensesLeft: number = countLicensesLeft(filteredContracts);
  const now = new Date();
  const anyCurrentContracts = filteredContracts
    ? filteredContracts.some((c) => c.contractStartDate < now && (!c.contractEndDate || now < c.contractEndDate))
    : null;
  const noContracts =
    licenseUsage && institutionContracts && filteredContracts.length == 0 && institutionContracts.length > 0;
  const used = licenseUsage
    ? licenseUsage.licenseUsage.consumed + INCLUDE_RESERVED * licenseUsage.licenseUsage.reserved
    : null;

  if (debugPrint) {
    const cc = classes.map((c) => c.id).join(', ');
    const acc = Object.keys(allClasses).join(', ');
    console.log(`***** institution ${institution.id} classes ${cc} from ${acc}`);
  }

  return (
    <RowDetails
      open={state.open}
      onToggle={(e) => {
        e.stopPropagation();
        onRowToggle(e.target);
      }}
      style={{ color: noContracts ? gray : state.open ? primary : black }}
    >
      <RowSummary>
        <strong>{displayName(institution)}</strong>
        {(debug || debugPrint) && <span> ({institution.id})</span>}:&nbsp;
        {licenseUsage ? (
          <>
            {licenseUsage.licenseUsage.classes ? (
              <>
                <b>{licenseUsage.licenseUsage.classes}</b> classes;&nbsp;{' '}
              </>
            ) : null}
            {licenseUsage.licenseUsage.students ? (
              <>
                <b>{licenseUsage.licenseUsage.students}</b> students;&nbsp;{' '}
              </>
            ) : null}
            {licenseUsage.licenseUsage.teachers ? (
              <>
                <b>{licenseUsage.licenseUsage.teachers}</b> teachers;&nbsp;{' '}
              </>
            ) : null}
            {noContracts ? (
              <>contract not selected</>
            ) : (
              <>
                <b>{used}</b> {usedText(used)}
                {debug && <span> ({licenseUsage.licenseUsage.reserved} reserved)</span>}
                {anyCurrentContracts && (
                  <>
                    ;&nbsp; <b>{licensesLeft}</b> {LicenseStatusText.unreservedPlural}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Text variant="p" color={gray}>
            Fetching statistics &hellip;
          </Text>
        )}
      </RowSummary>
      {children &&
        children.map((child) => <InstitutionRow institution={child} key={`institution-${child.id}`} filter={filter} />)}
      {institution.children.length > (children ? children.length : 0) && (
        <Text variant="p" color={gray}>
          Getting institution &hellip;
        </Text>
      )}
      {classes.map((theClass) => (
        <ClassRow classItem={theClass} key={`class-${theClass.id}`} filter={filter} />
      ))}
      {institution.classes.length > (classes ? classes.length : 0) && (
        <Text variant="p" color={gray}>
          Getting classes &hellip;
        </Text>
      )}
    </RowDetails>
  );
};
