// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';

import { black, primary } from '../../utils/colors';
import { tallyClass } from './util';

import { StudentRow } from './StudentRow';

import { ClassRowDetails, RowSummary } from '../../components/StyledComponents';
import { LicenseStatus } from '../../../types/models';
import { ClassWithUsers as Class, Contract } from '../../../types/routes/accountManagement';
import { INCLUDE_RESERVED, usedText } from './util';
import { classDisplayName } from '../../utils/class';

const joinList = (x: string[], ander = 'and') =>
  x.reduce(
    (a, b, i, array) =>
      a + (i < array.length - 1 ? ', ' : array.length > 2 ? ', ' + ander + ' ' : ' ' + ander + ' ') + b,
  );

export const ClassRow: React.FC<{ classItem: Class; filter: Contract[] }> = ({ classItem, filter }) => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const totals = tallyClass(classItem);

  // Use localStorage instead of REACT state mechanism
  const statePath = `admin/manage/class/${classItem.id}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };

  const onRowToggle = (target: EventTarget) => {
    state.open = (target as HTMLDetailsElement).open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
  };

  const licenseUsage = classItem.students.reduce(
    (y, x) => {
      const hasContract = filter.some((c) => c.id == x.contractId);
      return {
        reserved: (hasContract && x.licenseStatus == LicenseStatus.reserved ? 1 : 0) + y.reserved,
        consumed: (hasContract && x.licenseStatus == LicenseStatus.consumed ? 1 : 0) + y.consumed,
        other: (!hasContract && x.licenseStatus ? 1 : 0) + y.other,
      };
    },
    { reserved: 0, consumed: 0, other: 0 },
  );

  const notContract = licenseUsage.consumed + licenseUsage.reserved == 0 && licenseUsage.other > 0;

  const used = licenseUsage.consumed + INCLUDE_RESERVED * licenseUsage.reserved;

  return (
    <ClassRowDetails
      onToggle={(e) => {
        e.stopPropagation();
        onRowToggle(e.target);
      }}
      open={state.open}
      style={{ color: state.open ? primary : black, display: notContract ? 'none' : 'block' }}
    >
      <RowSummary>
        {classDisplayName(classItem, { section: true }) + (debug ? ' (' + classItem.id + ')' : '')}
        ;&nbsp;<b>{totals.studentIds.size}</b> students;&nbsp;
        {classItem.teachers.length > 0 &&
          joinList(
            classItem.teachers.map((teacher) => teacher.lastName || teacher.name),
            '&',
          )}
        {notContract && <>;&nbsp; contract not selected</>}
        {used > 0 && (
          <>
            ;&nbsp;<b>{used}</b> {usedText(used)}
          </>
        )}
      </RowSummary>
      <StudentList>
        <tbody>
          {classItem.students.map((student, i) => (
            <StudentRow classId={classItem.id} student={student} key={i} filter={filter} />
          ))}
        </tbody>
      </StudentList>
    </ClassRowDetails>
  );
};

export const StudentList = styled('table')({
  borderCollapse: 'separate',
  borderSpacing: '0.5rem 0.75rem',
});
