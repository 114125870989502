// @ts-strict-ignore
// This directory will be deleted as soon as live classroom is stable

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Dictionary } from 'lodash';

import { Class as ClassWithStudents } from '../../../types/routes/class';
import { ModuleEventWithUserClass } from '../../../types/models';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { StatsWrapperClassroom } from '../../components/StatsWrapperClassroom';
import { Text } from '../../components/text';
import { StudentInformation } from '../../components/StudentInformation';
import { ClassroomButtons } from './ClassroomButtons';
import { StudentList } from './studentList';
import { MessageModal } from './messageModal';
import { SettingsModal } from './SettingsModal';
import { ClassroomModal } from './classroomModal';
import { formatName } from '../../utils/user';
import { Modal } from '../../components/modal';
import { TaskSelector, ShowSetSelectedTask, TaskSelectorBackdrop } from '../../components/TaskSelector';
import {
  MessageModalState,
  SettingsModalState,
  ClassroomModalState,
  UserWithClassId,
  ActiveRun,
  ConfirmModalState,
} from './types';
import { gray } from '../../utils/colors';
import LiveIcon from '../../assets/icons/live.svg';
import { StudentButtons } from './StudentButtons';

interface Props {
  currentClass: ClassWithStudents;
  students: UserWithClassId[];
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  activeRunIds: { [studentId: string]: string };
  setActiveRunIds: (runIds: { [studentId: string]: string }) => void;
}

export const LivePage = () => {
  const params = useParams();
  return <StatsWrapperClassroom match={params} ChildComponent={Dashboard} pageTitle="Live Classroom" Icon={LiveIcon} />;
};

const Dashboard = ({ currentClass, students, eventsByStudent }: Props) => {
  const debugPrint = false;

  const [selectedStudent, setSelectedStudent] = useState<number | undefined>();
  const [messageModalState, setMessageModalState] = useState<MessageModalState>({
    open: false,
  });
  const [settingsModalState, setSettingsModalState] = useState<SettingsModalState>({
    open: false,
  });
  const [taskSelectorState, setTaskSelectorState] = useState<ShowSetSelectedTask | null>(null);
  const [classroomModalState, setClassroomModalState] = useState<ClassroomModalState>({
    open: false,
  });
  const [activeRuns, setActiveRuns] = useState<{ [userId: number]: ActiveRun }>({});
  const errorList = useState<{ [ref: string]: string }>({});
  const [confirmOrchestrationModalState, setConfirmOrchestrationModalState] = useState<ConfirmModalState>({
    open: false,
  });

  const topRef = useRef(undefined);
  useEffect(() => {
    if (topRef && topRef.current && selectedStudent) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedStudent]);

  if (debugPrint && selectedStudent && Number.isInteger(selectedStudent)) {
    const id = 35310;
    console.log(
      `*** student events for ${selectedStudent}\n   id=${id}: ${
        eventsByStudent[selectedStudent]
          ? eventsByStudent[selectedStudent].some((event) => event.id == id)
          : 'no matching event'
      }\n  runId: ${activeRuns[selectedStudent]?.selected.runId}`,
    );
  }

  return (
    <Container>
      <Tooltip anchorSelect=".supplement-header">
        <Text variant="nav">Supplemental activity</Text>
      </Tooltip>
      <Top>
        <ClassroomButtons
          classId={currentClass ? currentClass.id : null}
          students={students}
          eventsByStudent={eventsByStudent}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          setMessageModalState={setMessageModalState}
          setSettingsModalState={setSettingsModalState}
          setClassroomModalState={setClassroomModalState}
          setTaskSelectorState={setTaskSelectorState}
          setConfirmOrchestrationModalState={setConfirmOrchestrationModalState}
          errorList={errorList}
        />
      </Top>
      <Left>
        {students && (
          <StyledStudentList
            students={students}
            eventsByStudent={eventsByStudent}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            setMessageModalState={setMessageModalState}
            setClassroomModalState={setClassroomModalState}
            errorList={errorList}
          />
        )}
      </Left>
      <Right ref={topRef}>
        {Number.isInteger(selectedStudent) ? (
          <StyledStudentInformation
            student={students.find((s) => s.id === selectedStudent)}
            events={eventsByStudent[selectedStudent] || []}
            activeRun={activeRuns[selectedStudent] || null}
            setActiveRun={(runEvent: ActiveRun) =>
              setActiveRuns({
                ...activeRuns,
                [selectedStudent]: runEvent,
              })
            }
            setMessageModalState={setMessageModalState}
            setSettingsModalState={setSettingsModalState}
            setTaskSelectorState={setTaskSelectorState}
            HeaderRow={StudentButtons}
          />
        ) : (
          <ZeroText color={gray} variant="md">
            Click on a student to view their information.
          </ZeroText>
        )}
      </Right>
      <Modal
        isOpen={classroomModalState.open}
        closeModal={() => setClassroomModalState({ open: false })}
        title={'View students from several classes'}
      >
        <ClassroomModal theClass={currentClass} setClassroomModalState={setClassroomModalState} />
      </Modal>
      <Modal
        isOpen={messageModalState.open}
        closeModal={() => setMessageModalState({ open: false })}
        title={`Send ${messageModalState.student ? formatName(messageModalState.student) : 'all students'} a message`}
      >
        <MessageModal
          classIds={messageModalState.classIds}
          student={messageModalState.student}
          setMessageModalState={setMessageModalState}
          task={messageModalState.task}
        />
      </Modal>
      <Modal
        isOpen={settingsModalState.open}
        closeModal={() => setSettingsModalState({ open: false })}
        title={
          'Set Language for ' + (settingsModalState.student ? formatName(settingsModalState.student) : 'the class')
        }
      >
        <SettingsModal
          classIds={settingsModalState.classIds}
          student={settingsModalState.student}
          errorList={errorList}
          setSettingsModalState={setSettingsModalState}
        />
      </Modal>
      {taskSelectorState && (
        <>
          <TaskSelectorBackdrop clickedBackdrop={() => setTaskSelectorState(null)}></TaskSelectorBackdrop>
          <TaskSelector
            showTasks={taskSelectorState.showTasks}
            setSelectedTask={taskSelectorState.setSelectedTask}
            selectedTask={taskSelectorState.selectedTask}
            taskFilter={taskSelectorState.taskFilter ? taskSelectorState.taskFilter : () => true}
            firstTaskIsPart={taskSelectorState.firstTaskIsPart}
            setConfirmModalState={taskSelectorState.setConfirmModalState}
          ></TaskSelector>
        </>
      )}
      <Modal
        isOpen={confirmOrchestrationModalState.open}
        closeModal={() =>
          setConfirmOrchestrationModalState({
            open: false,
          })
        }
        title={'Are you sure you want to select this task?'}
        ctas={[
          {
            text: 'Confirm',
            onClick: () => {
              confirmOrchestrationModalState.onConfirm();
              setConfirmOrchestrationModalState({
                open: false,
              });
            },
          },
          {
            text: 'Cancel',
            onClick: () =>
              setConfirmOrchestrationModalState({
                open: false,
              }),
            variant: 'cancel',
          },
        ]}
      >
        <Text variant="p">Some students are using a version of the VR App that does not provide this feature.</Text>
      </Modal>
    </Container>
  );
};

const Container = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const Top = styled.div({
  width: '100%',
  margin: 0,
});

const Left = styled.div({
  width: '33%',
  margin: 0,
});

const StyledStudentList = styled(StudentList)({
  marginBottom: '2rem',
});

const Right = styled.div({
  width: '66%',
  display: 'flex',
  flexWrap: 'wrap',
  margin: 0,
});

const ZeroText = styled(Text)({
  margin: '6rem auto 0',
});

const StyledStudentInformation = styled(StudentInformation<(state: ShowSetSelectedTask) => void, UserWithClassId>)({
  flex: 1,
  height: '100%',
});
